import { graphql } from 'gatsby';
import React from 'react';
import tw from 'tailwind.macro';
import Button from '../../components/button';
import Grid from '../../components/grid';
import Layout from '../../components/layout';
import LeadText from '../../components/leadText';
import Masthead from '../../components/masthead';
import Panel from '../../components/panel';
import SafeLink from '../../components/safeLink';
import Section from '../../components/section';
import SEO from '../../components/seo';

const ProjectsSocialJusticeCafe = ({ data, location }) => {
  const parentTitle = 'Projects';
  const pageTitle = 'Social Justice Café';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[
          tw`bg-maroon bg-right`,
          `min-height:40vh; background-size:contain;`,
        ]}
      />
      <Section>
        <h2>Overview</h2>
        <LeadText>
          The aim of the cafés is to engage with young people on social justice
          issues and human rights-inspired democracy and action for inclusion,
          rooted in the UN Sustainable Development Goals (SDGs) and National
          Development goals, with a view to harnessing the youth dividend in
          ending poverty and reducing inequality by 2030.
        </LeadText>
        <p>
          As an initiative of the Centre for Social Justice, the cafés
          are executed as part of the{' '}
          <SafeLink to="/projects/m-plan/">
            Social Justice Musa Plan (M-Plan for Social Justice)
          </SafeLink>
          .
        </p>
        <hr css={tw`my-10`} />
        <h2>Format and logistics</h2>
        <p>
          Keynote speakers with extensive experience and expertise on a specific
          topic introduce and situate the theme, followed by a panel discussion
          involving approximately 4 panellists and the audience. Discussions
          take place on MS Teams.
        </p>
        <h2>Watch the latest Social Justice Cafés:</h2>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?si=PwSfF7SeOcmx3Ix3&amp;list=PLwTL48ihN7WaFJlSm-dEh4sYqEaLv0wk5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Section>
      <Section isShaded>
        <h2>Past and upcoming cafés</h2>
        <Grid>
          {data.latestEvents.edges.map(({ node }) => {
            return (
              <Panel css={tw`flex flex-col items-start`}>
                <SafeLink to={node.frontmatter.permalink}>
                  <h3>{node.frontmatter.title}</h3>
                  <p css={tw`mt-auto mb-0`}>{node.frontmatter.eventDate}</p>
                </SafeLink>
              </Panel>
            );
          })}
        </Grid>
      </Section>
    </Layout>
  );
};

export default ProjectsSocialJusticeCafe;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "projects-socialjusticecafe-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    latestEvents: allMarkdownRemark(
      filter: { frontmatter: { topics: { in: "Social Justice Café" } } }
      sort: { fields: [frontmatter___eventDate], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            author
            date(formatString: "D MMMM YYYY")
            permalink
            title
            topics
            eventDate(formatString: "D MMMM YYYY")
          }
        }
      }
    }
  }
`;
